import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import {Radio} from 'react-icheck';
import Calendar, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Helmet from 'react-helmet';


import { CUSTOMER_CC_ADD_API, GET_PAYMENT_METHOD_API, AWS_IMG_PATH } from '../Comman/Constant';


import CartSummeryWidegt from '../Widget/cartSummeryWidget';

class OrderStepOne extends Component {

    constructor(props){
        super(props);
        const from = localStorage.getItem('from') ? localStorage.getItem('from') : "";
        const to = localStorage.getItem('to') ? localStorage.getItem('to') : "";
        const schedule_slot = localStorage.getItem('schedule_slot');
        const delivery_type = localStorage.getItem('delivery_type');
        const delivery_date = localStorage.getItem('schedule_date');
        const shop_id = localStorage.getItem('shop_id');
        
        if(delivery_date){
            var delivery_date_array = delivery_date.split(","); 
        }

        var time = "23:59";
        var now = new Date().toLocaleString('en-US', { timeZone: 'Asia/Riyadh' });
        now = new Date(now);
        var dt = (now.getMonth()+1) + "/" + now.getDate() + "/" + now.getFullYear() + " " + time;    
       
        var userval = new Date(dt);    
       
        var defaultDay = 1;
        if (now > userval && (
            shop_id == 47 || 
            shop_id == 127 || 
            shop_id == 123 || 
            shop_id == 121 || 
            shop_id == 116 ||
            shop_id == 115 ||
            shop_id == 113 ||
            shop_id == 102 ||
            shop_id == 84 ||
            shop_id == 78 ||
            shop_id == 77        
            )){
            defaultDay = defaultDay + 1
        }

        this.state = {
            locationHistory: this.props.location,     
            firstname_order: '', 
            customer_email: '', 
            customer_mobile:'', 
            customer_order_notes: '',
            cvc: '',
            expiry: '',
            focused: '',
            name_card_order: '',
            card_number: '',
            add_card_toggle: false,
            customerCard: [],
            paymentMethodList: [],
            allowAddressArray: [],
            creditDate: new Date(),
            selectedCreditDate: "",
            from: from ? new Date(from) : undefined,
            to: to ? new Date(to) : undefined,
            schedule_slot: schedule_slot,
            delivery_type: delivery_type,
            selectedDays: delivery_date_array ? delivery_date_array : [],
            payment_method: "",
            calender_show: "no",
            shop_id: shop_id,
            defaultDay: defaultDay,
            is_charged_show: "no"
        }

        this.handleChange = this.handleChange.bind(this);
        this.addCardSubmit = this.addCardSubmit.bind(this);
        this.handlePaymentChange = this.handlePaymentChange.bind(this);
        this.add_card_toggle = this.add_card_toggle.bind(this);
    }

    componentDidMount(){
        const device_id = localStorage.getItem('device_id');            
        const customer_mobile = localStorage.getItem('customer_mobile');
        if(!customer_mobile){                              
            this.props.history.push('/sign-in?redirect_to=1');
            return false; 
        }
        if(!device_id){         
            this.props.history.push('/sign-in?redirect_to=1');       
            this.props.history.push('/your_location/');    
            return false;         
        }
        const requestData = {       
            language_id: 1,            
            device_id: device_id
        }
        this.fetchPaymentMethod(requestData);
        
    }

    forceReRander = () =>{
         const delivery_type = localStorage.getItem('delivery_type');
         const is_charged_show = localStorage.getItem('is_charged_show');

         this.setState({
             delivery_type: delivery_type,
             is_charged_show: is_charged_show,
         })
    }

    fetchPaymentMethod = (requestData) => {
        var that = this;
        axios.post(GET_PAYMENT_METHOD_API, requestData,
        { 
            headers: {
            'Authorization': requestData.device_id 
            }
        })
        .then((response) => { 
            this.setState({
                customerCard: response.data.card_list ? response.data.card_list : [],
                paymentMethodList: response.data.payment_list ? response.data.payment_list : [],
                payment_method: response.data.default_method,
                allowAddressArray: response.data.allow_address_array ? response.data.allow_address_array : [],
                allow_same_day_order: response.data.allow_same_day_order,
                calender_show:response.data.calender_show,
                add_card_toggle: false,  
                name_card_order:"",
                card_number:"",
                expire_month:"",
                cvc:"",       
                card_id: requestData.card_id ? requestData.card_id  : 0 ,
                selectedDate: '',
                cart_action:"",
                unique_id: requestData.card_id && new Date().getTime()
            })
        }).catch(function (error) {
               
                if(error.response.status==401){
                     that.props.history.push('/sign-in');
                }
        });;     
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        localStorage.setItem([event.target.name],event.target.value);
    }

    handleInputFocus = ({ target }) => {
        this.setState({
          focused: target.name
        });
    };
      
    handleInputNameChange = (e) => {

        const value = e.target.value.replace(/[^A-Za-z \\s]/g, '');
        const name = e.target.name;

        this.setState({ [name]: value });
    }

    handleInputCardNumberChange = (e) => {

        const name = e.target.name;
        var value =  e.target.value.replace(/[^0-9\.]/g, '');
        var splitVlaue = value.split(" ").join("");
        
        if (splitVlaue.length > 0) {
			splitVlaue = splitVlaue.match(new RegExp('.{1,4}', 'g')).join(" ");
		}

        this.setState({ [name]: splitVlaue });
    }

    handleInputExpireChange = (e) => {

        const name = e.target.name;
       
        var value = e.target.value.replace(/[^0-9\.]/g, '');
		var splitVlaue = value.split("/").join("");

		if (splitVlaue.length > 0) {
			splitVlaue = splitVlaue.match(new RegExp('.{1,2}', 'g')).join("/");
		}

        this.setState({ [name]: splitVlaue });
    }

    handleInputCvvChange = (e) => {

        const name = e.target.name;
    
        var value = e.target.value.replace(/[^0-9\.]/g, '');
        this.setState({ [name]: value });
    }

    handlePaymentChange = (e) => {

        const name = e.target.name;
        const value = e.target.value;
        const payment_method_label = e.target.dataset.payment_method_name;

        this.setState({ 
            [name]: value,
            payment_method_label: payment_method_label,
            card_id: 0,
            selectedDate: '',
            cart_action:"",
            unique_id: new Date().getTime()
        
         });

    }

    onChangeScheduletDate = (day, { selected }) => {
        const selectedDays = this.state.selectedDays.concat();
        var now = new Date();
        if (day > now || this.state.allow_same_day_order == 'yes') {
            if (selected) {            
                const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(new Date(selectedDay), day));
                
                selectedDays.splice(selectedIndex, 1);

                this.setState({ 
                    selectedDays: selectedDays,
                    selectedDate: selectedDays,
                    cart_action:"scheduleDateChange",
                    unique_id: new Date().getTime()
                });

            } else {
                selectedDays.push(day);
                this.setState({ 
                    selectedDays: selectedDays,
                    selectedDate: day,
                    cart_action:"scheduleDateChange",
                    unique_id: new Date().getTime()
                });
            }
            localStorage.setItem('schedule_date',selectedDays);
            
        } 
    }

    onChangeCreditDate = (date) => {

        var now = new Date().toLocaleString("en-US", {timeZone: "Asia/Riyadh"});
        var fullYear = new Date(date).getFullYear();
        var getMonth = new Date(date).getMonth() < 9 ? "0" + (new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1);
        var getDate = new Date(date).getDate() < 10 ? "0" + new Date(date).getDate() : new Date(date).getDate();
      
        var fullDate = fullYear +"-"+ getMonth +"-"+ getDate;

        this.setState({ 
            creditDate: date ,
            selectedCreditDate: fullDate ,
            selectedDate: '',
            cart_action:"",
            unique_id: new Date().getTime()
         });
    }

    saveScheduleTime = (event) => {
       
        const target = event.currentTarget;
        var value = target.value;
       
        localStorage.setItem('schedule_order','yes');
        localStorage.setItem('schedule_slot', value);    
        localStorage.setItem('delivery_type','schedule');
      
        this.setState({
            schedule_order : 'yes',
            schedule_slot : value,
            delivery_type: 'schedule',        
           // unique_id: new Date().getTime()
        });
    }

    selectCard = (e) => {

        const card_id = e.target.dataset.enctid;
        e.preventDefault();
        this.setState({ 
            card_id: card_id,
            selectedDate: '',
            cart_action:"",
            unique_id: new Date().getTime()
         });
    }

    add_card_toggle = () =>{
        this.setState({
            add_card_toggle: true
        })
    }

    addCardSubmit(event) {
         var that = this;
        const device_id = localStorage.getItem('device_id');
        const cardData = {       
            language_id: 1,    
            exp_mm: this.state.expire_month,    
            exp_yy: this.state.expire_month,
            card_name: this.state.name_card_order,
            card_no: this.state.card_number,
            device_id: device_id
        }
       
        axios.post(CUSTOMER_CC_ADD_API, cardData,
            { 
            headers: {
            'Authorization': cardData.device_id 
            }
        })
        .then((response) => { 
            const device_id = localStorage.getItem('device_id');    
            const requestData = {       
                language_id: 1,            
                device_id: device_id,
                card_id: response.data.card_id
            }

            this.setState({
                customerCard: response.data.card_list ? response.data.card_list : [],
                //paymentMethodList: response.data.payment_list ? response.data.payment_list : [],
                //payment_method: response.data.default_method,
                //calender_show:response.data.calender_show,
                add_card_toggle: false,  
                name_card_order:"",
                card_number:"",
                expire_month:"",
                cvc:"",       
                card_id: requestData.card_id ? requestData.card_id  : 0 ,
                selectedDate: '',
                cart_action:"",
                unique_id: requestData.card_id && new Date().getTime()
            });
        
            //this.fetchPaymentMethod(requestData);
        }).catch(function (error) {
               
                if(error.response.status==401){
                     that.props.history.push('/sign-in');
                }
        });;     
        event.preventDefault();
    }

    render(){

        const locationData = this.state.locationHistory;
        const customerCard = this.state.customerCard;
        const paymentMethodList = this.state.paymentMethodList;
        const allowAddressArray = this.state.allowAddressArray;
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        const currentYear = new Date().getFullYear();
        const fromMonth = new Date(currentYear, new Date().getMonth());
    
        return(
            <div>
                <section className="parallax-window" id="cs_short" style={{backgroundImage: `url(${AWS_IMG_PATH + "extra_images/shop_detail_default_banner.png"})`}}>
                    <div id="subheader">
                        <div id="sub_content">
                            <h1>Place your order</h1>
                            <div className="bs-wizard row">
                                <div className="col-4 bs-wizard-step active">
                                    <div className="text-center bs-wizard-stepnum"><strong>1.</strong> Your details</div>
                                    <div className="progress">
                                        <div className="progress-bar"></div>
                                    </div>
                                    <Link className="bs-wizard-dot" to="/order_step_one"></Link>
                                </div>
                                <div className="col-4 bs-wizard-step disabled">
                                    <div className="text-center bs-wizard-stepnum"><strong>2.</strong> Payment</div>
                                    <div className="progress">
                                        <div className="progress-bar"></div>
                                    </div>
                                    <Link className="bs-wizard-dot" to="/order_step_two"></Link> 
                                </div>
                                <div className="col-4 bs-wizard-step disabled">
                                    <div className="text-center bs-wizard-stepnum"><strong>3.</strong> Finish!</div>
                                    <div className="progress">
                                        <div className="progress-bar"></div>
                                    </div>
                                    <a href="cart_3.html" className="bs-wizard-dot"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container-fluid full-height mt-3">
                    <div className="row">
                        
                        <div className="col-lg-3">
                            <div className="box_style_2" id="order_process">
                                <h2 className="inner">Your order details</h2>
                               
                                <div className="row">
                                    {
                                        this.state.delivery_type == 'schedule' && 
                                        <>
                                         <div className="col-lg-12">
                                            <label>Choose Delivery date </label>
                                        
                                            <Calendar 
                                                month={fromMonth} 
                                                //disabledDays={{ before: this.state.allow_same_day_order == 'yes'  ? new Date(Date.now()) : new Date(Date.now() + this.state.defaultDay * 24 * 60 * 60 * 1000) }}                                         
                                                className="Selectable"
                                                //selectedDays={this.state.selectedDays}
                                                selectedDays={this.state.selectedDays.map(date => new Date(date))}
                                                modifiers={modifiers}
                                                fromMonth={fromMonth}
                                                onDayClick={this.onChangeScheduletDate}
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <label>Choose Delivery time</label>
                                            <select id='saveScheduleTime' className="form-control" onChange={this.saveScheduleTime}>
                                                <option value="00:00" selected={this.state.schedule_slot == "00:00" && true}>00:00</option>
                                                <option value="00:30" selected={this.state.schedule_slot == "00:30" && true}>00:30</option>
                                                <option value="01:00" selected={this.state.schedule_slot == "01:00" && true}>01:00</option>
                                                <option value="01:30" selected={this.state.schedule_slot == "01:30" && true}>01:30</option>
                                                <option value="02:00" selected={this.state.schedule_slot == "02:00" && true}>02:00</option>
                                                <option value="02:30" selected={this.state.schedule_slot == "02:30" && true}>02:30</option>
                                                <option value="03:00" selected={this.state.schedule_slot == "03:00" && true}>03:00</option>
                                                <option value="03:30" selected={this.state.schedule_slot == "03:30" && true}>03:30</option>
                                                <option value="04:00" selected={this.state.schedule_slot == "04:00" && true}>04:00</option>
                                                <option value="04:30" selected={this.state.schedule_slot == "04:30" && true}>04:30</option>
                                                <option value="05:00" selected={this.state.schedule_slot == "05:00" && true}>05:00</option>
                                                <option value="05:30" selected={this.state.schedule_slot == "05:30" && true}>05:30</option>
                                                <option value="06:00" selected={this.state.schedule_slot == "06:00" && true}>06:00</option>
                                                <option value="06:30" selected={this.state.schedule_slot == "06:30" && true}>06:30</option>
                                                <option value="07:00" selected={this.state.schedule_slot == "07:00" && true}>07:00</option>
                                                <option value="07:30" selected={this.state.schedule_slot == "07:30" && true}>07:30</option>
                                                <option value="08:00" selected={this.state.schedule_slot == "08:00" && true}>08:00</option>
                                                <option value="08:30" selected={this.state.schedule_slot == "08:30" && true}>08:30</option>
                                                <option value="09:00" selected={this.state.schedule_slot == "09:00" && true}>09:00</option>
                                                <option value="09:30" selected={this.state.schedule_slot == "09:30" && true}>09:30</option>
                                                <option value="10:00" selected={this.state.schedule_slot == "10:00" && true}>10:00</option>
                                                <option value="10:30" selected={this.state.schedule_slot == "10:30" && true}>10:30</option>
                                                <option value="11:00" selected={this.state.schedule_slot == "11:00" && true}>11:00</option>
                                                <option value="11:30" selected={this.state.schedule_slot == "11:30" && true}>11:30</option>
                                                <option value="12:00" selected={this.state.schedule_slot == "12:00" && true}>12:00</option>
                                                <option value="12:30" selected={this.state.schedule_slot == "12:30" && true}>12:30</option>
                                                <option value="13:00" selected={this.state.schedule_slot == "13:00" && true}>13:00</option>
                                                <option value="13:30" selected={this.state.schedule_slot == "13:30" && true}>13:30</option>
                                                <option value="14:00" selected={this.state.schedule_slot == "14:00" && true}>14:00</option>
                                                <option value="14:30" selected={this.state.schedule_slot == "14:30" && true}>14:30</option>
                                                <option value="15:00" selected={this.state.schedule_slot == "15:00" && true}>15:00</option>
                                                <option value="15:30" selected={this.state.schedule_slot == "15:30" && true}>15:30</option>
                                                <option value="16:00" selected={this.state.schedule_slot == "16:00" && true}>16:00</option>
                                                <option value="16:30" selected={this.state.schedule_slot == "16:30" && true}>16:30</option>
                                                <option value="17:00" selected={this.state.schedule_slot == "17:00" && true}>17:00</option>
                                                <option value="17:30" selected={this.state.schedule_slot == "17:30" && true}>17:30</option>
                                                <option value="18:00" selected={this.state.schedule_slot == "18:00 "&& true}>18:00</option>
                                                <option value="18:30" selected={this.state.schedule_slot == "18:30 "&& true}>18:30</option>
                                                <option value="19:00" selected={this.state.schedule_slot == "19:00" && true}>19:00</option>
                                                <option value="19:30" selected={this.state.schedule_slot == "19:30" && true}>19:30</option>
                                                <option value="20:00" selected={this.state.schedule_slot == "20:00" && true}>20:00</option>
                                                <option value="20:30" selected={this.state.schedule_slot == "20:30" && true}>20:30</option>
                                                <option value="21:00" selected={this.state.schedule_slot == "21:00" && true}>21:00</option>
                                                <option value="21:30" selected={this.state.schedule_slot == "21:30" && true}>21:30</option>
                                                <option value="22:00" selected={this.state.schedule_slot == "22:00" && true}>22:00</option>
                                                <option value="22:30" selected={this.state.schedule_slot == "22:30" && true}>22:30</option>
                                                <option value="23:00" selected={this.state.schedule_slot == "23:00" && true}>23:00</option>
                                                <option value="23:30" selected={this.state.schedule_slot == "23:30" && true}>23:30</option>
                                            
                                            </select>
                                        </div>
                                        </>
                                    }

                                    {
                                        this.state.shop_id == 57 || 
                                        this.state.shop_id == 79 || 
                                        this.state.shop_id == 118 ||
                                        this.state.shop_id == 154 ||
                                        this.state.shop_id == 124 ?
                                        <div className="col-lg-12">
                                            <label>Customer Mobile</label>
                                            <input type="text" name="customer_mobile_no" id="customer_mobile_no" className="form-control" placeholder="Customer Mobile" value={localStorage.getItem('customer_mobile_no')} onChange={this.handleChange}/>
                                        </div> : null
                                    }

                                    {
                                        this.state.is_charged_show == 'yes' ?
                                        <div className="col-lg-12">
                                            <label>Charged Code</label>
                                            <input type="text" name="charged_code" id="charged_code" className="form-control" placeholder="Charged Code" value={localStorage.getItem('charged_code')} onChange={this.handleChange}/>
                                        </div> : null
                                    }
                                      
                                    
                                    <div className="col-lg-12">
                                        <label>Notes for the restaurant</label>
                                        <textarea className="form-control" style={{height:'150px'}} placeholder="Ex. Allergies, cash change..." name="customer_order_notes" id="notes" value={localStorage.getItem('customer_order_notes')} onChange={this.handleChange}></textarea>
                                    </div>
                                </div>
                            </div>        
                        </div>    


                        <div className="col-lg-3">

                        <div className="box_style_2" id="payment_process">
                                <h2 className="inner">Payment methods</h2>
                                <div className="pm_list_container">
                                    {
                                        paymentMethodList.map( (methodData,methodKey) => {
                                            return(                                                                                                
                                            <div class="payment_select" key={methodKey}>
                                                <label>
                                                    <Radio
                                                    name="payment_method" 
                                                    radioClass="iradio_square-grey payment_method_list"   
                                                    data-payment_method_name={methodData.payment_method_name}                                                
                                                    label={methodData.payment_method_name}
                                                    value={methodData.payment_method_code}
                                                    onClick={this.handlePaymentChange} 
                                                    checked={this.state.payment_method == methodData.payment_method_code}
                                                    />
                                                    
                                                </label>
                                                <i class={methodData.payment_method_img_on}></i>
                                            </div>
                                            ) 
                                        })
                                    }
                                </div>

                                 
                                <div className="credit_Card_container">
                                    { 
                                        this.state.payment_method == 'credit' && this.state.calender_show == 'yes' ?
                                    
                                        <Calendar showOutsideDays 
                                            month={fromMonth} 
                                            disabledDays={{ before: new Date() }}                                         
                                            selectedDays={this.state.creditDate}
                                            onDayClick={this.onChangeCreditDate}
                                            fromMonth={fromMonth}
                                        />
                                        : null
                                    }
                                </div>

                                <div className="credit_Card_container">
                                { 
                                this.state.payment_method == 'card' || this.state.payment_method == 'mada' ?
                                    <>
                                    {
                                         this.state.add_card_toggle == false &&
                                         <div className="form-group">
                                            <input type="button" className="btn_full" name="add_card_toggle" value="ADD CARD" onClick={this.add_card_toggle} />
                                        </div>
                                    }
                                    
                                    <div className="form-group">
                                                
                                        {
                                            this.state.add_card_toggle == false &&
                                            customerCard.map( (cardData,cardKey) => {
                                                return(     
                                                    <>
                                                    {   
                                                    this.state.payment_method == cardData.card_type &&
                                                        <a href="#" key={cardData.id} data-enctid={cardData.id} data-cname={cardData.card_name} data-cnumber={cardData.card_number} data-cexpire={cardData.exp_month} onClick={this.selectCard}>                                                                                           
                                                            <div  data-enctid={cardData.id} data-cname={cardData.card_name} data-cnumber={cardData.card_number} data-cexpire={cardData.exp_month} onClick={this.selectCard} className={cardData.card_number.charAt(0) == 4 ? " credit-card selectable visa" : "credit-card selectable mastercard"}>
                                                                <div className="credit-card-last4"  data-enctid={cardData.id} data-cname={cardData.card_name} data-cnumber={cardData.card_number} data-cexpire={cardData.exp_month} onClick={this.selectCard}>
                                                                    { cardData.card_number.substr(cardData.card_number.length - 4)  }
                                                                </div>
                                                                <div className="credit-card-expiry" data-enctid={cardData.id} data-cname={cardData.card_name} data-cnumber={cardData.card_number} data-cexpire={cardData.exp_month} onClick={this.selectCard}>
                                                                    {cardData.exp_month}
                                                                </div>
                                                            </div>                               
                                                        </a>
                                                    }
                                                    </>
                                                ); 
                                            
                                            })
                                        }
                                    </div>
                                            
                                            
                                    <div class="add_card_toggle">
                                        {
                                            this.state.add_card_toggle == true ?
                                        
                                            <div class="form-group add_card_toggle">

                                                <div class="form-group">                      
                                                <Cards
                                                    cvc={this.state.cvc}
                                                    expiry={this.state.expire_month}
                                                    name={this.state.name_card_order}
                                                    number={this.state.card_number}   
                                                    focused={this.state.focused}                                 
                                                />
                                                </div>
                                            
                                                <form onSubmit={this.addCardSubmit}>
                                                    <div class="form-group">
                                                        <label>Name on card</label>
                                                        <input 
                                                            type="text" 
                                                            className="form-control"
                                                            id="name_card_order" 
                                                            name="name_card_order" 
                                                            placeholder="First and last name" 
                                                            required="required"         
                                                            value={this.state.name_card_order}    
                                                            onFocus={this.handleInputFocus}                          
                                                            onChange={this.handleInputNameChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Card number</label>                                
                                                        <input 
                                                            type="text" 
                                                            id="card_number" 
                                                            name="card_number" 
                                                            class="form-control"                                             
                                                            maxlength="19"  
                                                            pattern=".{19,}"                                          
                                                            required="required"
                                                            value={this.state.card_number}
                                                            placeholder="xxxx xxxx xxxx xxxx" 
                                                            onFocus={this.handleInputFocus} 
                                                            onChange={this.handleInputCardNumberChange}/>

                                                    </div>

                                                    <div class="row">
                                                    <div class="col-md-6">
                                                        <label>Expiration date</label>
                                                        <div class="row">
                                                            <div class="col-8">
                                                                <div class="form-group">
                                                                    <input 
                                                                        type="text" 
                                                                        name="expire_month" 
                                                                        id="expire_month" 
                                                                        class="form-control" 
                                                                        placeholder="12/21" 
                                                                        maxlength="5" 
                                                                        pattern=".{5,}"
                                                                        required="required"
                                                                        value={this.state.expire_month}
                                                                        onFocus={this.handleInputFocus} 
                                                                        onChange={this.handleInputExpireChange}/>
                                                                </div>
                                                            </div>
                                                
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-sm-12">
                                                        <div class="form-group">
                                                            <label>Security code</label>
                                                            <div class="row">
                                                                <div class="col-md-6 col-sm-6">
                                                                    <div class="form-group">
                                                                        <input 
                                                                            type="text" 
                                                                            id="ccv" 
                                                                            name="cvc" 
                                                                            class="form-control" 
                                                                            placeholder="CCV" 
                                                                            maxlength="3"
                                                                            pattern=".{3,}"
                                                                            required="required"
                                                                            value={this.state.cvc}
                                                                            onFocus={this.handleInputFocus}
                                                                            onChange={this.handleInputCvvChange}/>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-sm-6">
                                                                    <img src={AWS_IMG_PATH + "extra_images/icon_ccv.gif"}  width="50" height="29" alt="ccv" /><small>Last 3 digits</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>

                                                    <div class="row">
                                                    <div class="col-md-12">
                                                        <input type="submit" className="btn_full" value="ADD CARD" name="add_card" />
                                                    </div>
                                                    <hr />
                                                    </div>
                                                </form>
                                            </div>
                                        : null
                                        }   
                                    </div>
                                    </>
                                    : null
                               }
                               </div>
                               
                        

                            </div>
                        </div>

                        <div className="col-lg-6" id="sidebar">
                           
                            <CartSummeryWidegt 
                                key={this.state.unique_id} 
                                location={locationData} 
                                customer_data={this.state.firstname_order} 
                                payment_method={this.state.payment_method}
                                payment_method_label={this.state.payment_method_label}
                                card_id={this.state.card_id}
                                selectedCreditDate={this.state.selectedCreditDate}
                                selectedDays={this.state.selectedDays}
                                rerenderParentCallback={this.forceReRander}
                                selectedDate={this.state.selectedDate}
                                cart_action={this.state.cart_action}
                                />          
                                                 
                        </div>
                    </div>                
                </div>
        
            </div>
        )
    }
}

export default OrderStepOne;